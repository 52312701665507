import * as React from "react"
import {css} from "@emotion/react"
import {f} from "~/emotionStyles/function"


const Kv = ({data,img}) =>{
  return(
    <div css={wrap}>
      <img src={img} alt={data.kv.title}/>
      <div css={titleArea}>
        <p css={number}>{data.kv.number}</p>
        <h2 css={title}>{data.kv.title}</h2>
        <p css={text}>{data.kv.text}</p>
        <p css={text}>{data.kv.date}</p>
      </div>
    </div>
  )  
}

const wrap = css({
  width:'100%',
  position:'relative'
})

const titleArea = css({
  width:'100%',
  position:'absolute',
  top:'50%',
  left:'50%',
  transform:'translate(-50%,-50%)',
  color:"#fff",
  textAlign:"center",
  lineHeight:'200%',
  letterSpacing:'.04em'
})

const title = css({
  marginBottom:'20px',
  letterSpacing:'.04em',
  [f.pc()]: {
    fontSize:'32px',
    fontWeight:'bold',
  },
  [f.sp()]: {
    fontSize:'24px',
    fontWeight:'bold',
  }
})
const number = css({
  marginBottom:'15px',
  [f.pc()]: {
    fontSize:'20px',
    fontWeight:'bold',
  },
  [f.sp()]: {
    fontSize:'18px',
    fontWeight:'bold',
  }
})
const text = css({
  [f.pc()]: {
    fontSize:'16px',
  },
  [f.sp()]: {
    fontSize:'14px',
  }
})


export default Kv