/* eslint-disable import/first */
import * as React from "react"
import Swiper from "react-id-swiper";
import { Autoplay, Swiper as RealSwiper } from "swiper";
RealSwiper.use([Autoplay]);

import {css} from "@emotion/react"
import {f} from "~/emotionStyles/function"

const Slider = ({imageNum,title,stageNum}) => {
  const params = {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
  }
  const imgList = []
  const addImg = () => {
    for(let i = 0 ; i < imageNum ; i++){
      imgList.push(<div key={i}><img src={`/images/stage${stageNum}/stage${stageNum}-${i}.jpg`} alt="img" /></div>)
    }
  }
  addImg()
  return (
    <div css={contentsWrap}>
      <h3 css={contentsTitle}>{title}</h3>
      <Swiper {...params}>
        {imgList}
      </Swiper>
    </div>
    
  )
};

const contentsWrap = css({
  width:'100%',
  [f.pc()]: {
    paddingTop: f.vw(80),
  },
  [f.sp()]: {
    paddingTop: f.vw(120),
  },
})

const contentsTitle = css({
  [f.pc()]: {
    fontSize:'32px',
    fontWeight:'bold',
    color:'#333',
    marginBottom:'35px'
  },
  [f.sp()]: {
    fontSize:'24px',
    fontWeight:'bold',
    color:'#333',
    marginBottom:'20px'
  }
})
export default Slider;